<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>User </strong><small>Tambah Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputUser">
            <CRow>
              <CCol sm="6">
              <CInput
                v-model="username"
                label="Username"
                placeholder="Input username"
              />
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="nama_lengkap"
                label="Nama Lengkap"
                placeholder="Input nama lengkap"
              />  
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="email"
                label="Email"
                placeholder="Input email"
              />
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="pass"
                label="Password"
                placeholder="Input password"
              />  
              </CCol> 
              <CCol sm="6">
                <div class="form-group">
                <label> Level</label>
                <select v-model="level" placeholder='Pilih Level' class="form-control">
                  <option value="" disabled selected>Pilih Level</option>
                  <option value="1">Administrator</option>
                  <option value="2">Kontraktor</option>
                </select>
                </div>
              </CCol>      
            </CRow>          
                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary" to="/user">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data User gagal diinput.
    </CModal>
   
  </div>
</template>
<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  data () {
    return {
      username : "",
      nama_lengkap : "",
      email : "",
      pass : "",
      level : "",
      selected: [], // Must be an array reference!
      show: true,
    }
  },
  methods: {
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    inputUser: function(event){
      // Simple POST request with a JSON body using axios
      const user = { 
                          username:this.username,
                          nama_lengkap: this.nama_lengkap, 
                          email: this.email, 
                          pass: this.pass, 
                          level: this.level, 
                          };
      // alert(JSON.stringify(user));
      axios.post("https://sippd.probowsolution.com/public/adduser", user)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.data=='sukses'){
              this.$router.push('/user');
            }
            else{
              this.$root.$emit("bv::show::modal", "modalError");
            }            
        })
    }
  }
}
</script>
